

$(function(){
    app.init();
})

$menuBtn = $(".menu-btn");
$body = $("body");
$mobileNav = $(".mobile-nav");
$mobileNavLinks = $(".mobile-nav a");
$html = $('html');
$tooltipContainer = $(".tooltip-trigger");
$yearTicker = $('.year-ticker');
$slideshow = $('.slideshow');
$timeline = $(".timeline");
$vertical = $(".vertical");
$fadeIn = $('.fade-in');
$parallax = $('[data-speed]');
$musicBottom = $(".music.bottom");
$musicTop = $(".music.top");
$blur = $(".blur");
$mobileHome = $(".mobile_logo");
$parentLinks = $('.desktop-nav > ul > li');



const app = {}

app.keys = {
    "left": 37,
    "right": 39,
    "esc": 27,
    "tab": 9,
    "shift":16
}
app.mobileMenu = function(){
    $mobileNavLinks.on("click", () => {
        $menuBtn.toggleClass("open");
        $mobileNav.toggleClass("open");
        $body.toggleClass("menu-open");
        document.documentElement.classList.toggle("menu-open");
    });
    $mobileHome.on("click", ()=> {
      $menuBtn.removeClass("open");
      $mobileNav.removeClass("open");
      $body.removeClass("menu-open");
      document.documentElement.classList.remove("menu-open");
    })
    $mobileNav.find("a").attr("tabindex", -1);
    $menuBtn.on("click", () => {
        $menuBtn.toggleClass("open");
        $mobileNav.toggleClass("open");
        $body.toggleClass("menu-open");
        document.documentElement.classList.toggle("menu-open");
        app.trapFocus();
        if($menuBtn.hasClass("open")){
            $menuBtn.attr("aria-label", "Close Mobile Navigation Menu");
            $mobileNavLinks.each(function(){
                $(this).attr("tabindex", 0);
            });
            $mobileNav.attr("aria-hidden", "false");
        } else {
            $menuBtn.attr("aria-label", "Mobile Navigation Menu");
            $mobileNav.attr("aria-hidden", "true");
            $menuBtn.focus();
            $mobileNavLinks.each(function(){
                $(this).attr("tabindex", -1);
            });
        }
    });
}
app.trapFocus = function(){
    let links = $mobileNav.find("a, button");
    let lastLink = links[(links.length)-1];
    const secondLink = links[0];
    let firstLink = $menuBtn;
    firstLink.focus();
    let keysPressed={};
    firstLink.on("keydown", (e) => {
        let key = e.keyCode;
        keysPressed[e.key] = true;
        if(key == app.keys.esc) {
            firstLink.trigger("click");
        } else if (keysPressed["Tab"] && keysPressed["Shift"]){
            e.preventDefault();
            lastLink.focus();
        }else if (keysPressed["Tab"]){
          e.preventDefault();
          secondLink.focus();
        }
    });
    firstLink.on("keyup", (e) => {
      delete keysPressed[e.key];
   });
   links.on("keydown", (e) => {
      let key = e.keyCode;
      keysPressed[e.key] = true;
      if(key == app.keys.esc) {
          firstLink.trigger("click");
      } else if (key == app.keys.tab && !keysPressed["Shift"]){
            if ($(document.activeElement).is(lastLink)) {
               e.preventDefault();
               firstLink.focus();
            }
      } else if (keysPressed["Tab"] && keysPressed["Shift"]) {
        if ($(document.activeElement).is(secondLink)) {
               e.preventDefault();
               firstLink.focus();
            }
      }
   });
   links.on("keyup", (e) => {
      delete keysPressed[e.key];
   });
}

app.tooltip = function(){
      $tooltipContainer.each(function() {
         const tooltip = $(this).find('.tooltip');
         if(ScrollTrigger.isTouch === 0 || ScrollTrigger.isTouch === 2){
            $(this).on('mouseenter', function() {
               app.showTooltip(tooltip);
            });
            $(this).on('focus', function() {
               app.showTooltip(tooltip);
            });
            
            $(this).on('mouseleave', function(){
               app.timeoutTooltip(tooltip);
            });
            $(this).on('blur', function(){
               app.hideTooltip(tooltip);
            });
      } else {
         $(this).on('click', function() {
          tooltip.toggleClass('show');
          if(tooltip.hasClass("show")){
            tooltip.attr('aria-hidden', 'false');
          } else {
            tooltip.attr('aria-hidden', 'true');
          }
        });
      }
})}

app.timeouts = new WeakMap();

app.showTooltip = function(tooltip){
   if (app.timeouts.has(tooltip[0])) {
      window.clearTimeout(app.timeouts.get(tooltip[0]));
   }
   tooltip.addClass('show');
   tooltip.attr('aria-hidden', 'false');
}
app.hideTooltip = function(tooltip){
  tooltip.removeClass('show');
  tooltip.attr('aria-hidden', 'true');
}
app.timeoutTooltip = function(tooltip){
   let timeoutId = window.setTimeout(function() {
      app.hideTooltip(tooltip);
   }, 200);
   app.timeouts.set(tooltip[0], timeoutId);
}
app.slider = function(){
   $yearTicker.slick({
      speed: 20000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: true,
   })
   $slideshow.slick({
      dots:true,
      nextArrow: '<button aria-label="next image" type="button" role="button" class="slick-next slick-arrow"><svg class="background" width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.49 47.5036C20.0137 47.3973 0.992531 30.2212 0.992531 23.8938L0.992531 24.059C0.992531 17.7552 19.99 0.579071 24.49 0.449218L24.5494 0.449218C29.0494 0.555462 48.0469 17.7316 48.0469 24.059L48.0469 23.941C48.0469 30.2448 29.0494 47.4209 24.5494 47.5508L24.49 47.5036Z" fill="black"/></svg><svg class="arrow" width="15px" height="19px" viewBox="0 0 25 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M2.15,13.5 L22.85,13.5 M11.35,2.13636364 L22.85,13.5 M11.35,24.8636364 L22.85,13.5" stroke="#fff" stroke-width="4"></path></g></svg></button>',
      prevArrow:'<button type="button" role="button" aria-label="previous image" class="slick-prev slick-arrow"><svg class="background" width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.49 47.5036C20.0137 47.3973 0.992531 30.2212 0.992531 23.8938L0.992531 24.059C0.992531 17.7552 19.99 0.579071 24.49 0.449218L24.5494 0.449218C29.0494 0.555462 48.0469 17.7316 48.0469 24.059L48.0469 23.941C48.0469 30.2448 29.0494 47.4209 24.5494 47.5508L24.49 47.5036Z" fill="black"/></svg><svg class="arrow" width="15px" height="19px" viewBox="0 0 25 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M2.15,13.5 L22.85,13.5 M11.35,2.13636364 L22.85,13.5 M11.35,24.8636364 L22.85,13.5" stroke="#fff" stroke-width="4" transform="translate(12.500000, 13.500000) scale(-1, 1) translate(-12.500000, -13.500000) "></path></g></svg></div>'
   });
}
app.horizontalScroll = function(){

gsap.matchMedia().add("(min-width: 801px)", function(){
      if(ScrollTrigger.isTouch === 0 || ScrollTrigger.isTouch === 2){
         $body.addClass("mouse");
         ScrollTrigger.normalizeScroll(true);
            
         let panelsContainer = document.querySelector(".timeline");
         let tween = gsap.to(panelsContainer, {
            xPercent: -100,
            x: () => window.innerWidth,
            ease: "none"
         });
            let horizontalScroll = ScrollTrigger.create({
            trigger: "main",
            pin:true,
            scrub: 1,
            anticipatePin: 3,
            start: 'top top',
            end: () =>  "+=" + (panelsContainer.offsetWidth),
            animation:tween,
            invalidateOnRefresh: true
         });
            if(ScrollTrigger.isTouch === 2) {
               dragRatio = $timeline.innerWidth()/($timeline.innerWidth() - window.innerWidth);
               const drag = Draggable.create('.drag-proxy', {
                  trigger: 'main',
                  type: "x",
                  onPress() {
                     this.startScroll = horizontalScroll.scroll();
                  },
                  onDrag() {
                     horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * 3);
                  }
               })[0];
            }
         document.querySelectorAll(".anchor").forEach(anchor => {
            anchor.addEventListener("click", function(e) {
               e.preventDefault();
               const id = this.getAttribute('href').split('#')[1];
               $(this).blur();
               $parentLinks.removeClass('show-sub');
   
               const targetElement = document.getElementById(id);
               let parentOffset = 0;
               if(targetElement.classList.contains('year')){
                  parentOffset = targetElement.closest('.era').offsetLeft;
               }
               const scrollToHere = (targetElement.offsetLeft + parentOffset) * (panelsContainer.scrollWidth/(panelsContainer.scrollWidth - window.innerWidth));
               gsap.to(window, {duration: 2, scrollTo: scrollToHere});
               });
         });
         $vertical.each(function() {
            const parent = $(this).parents(".era");
            const el = $(this);
            gsap.to(el, {
               left: '14vw',
               ease: "none",
               scrollTrigger: {
                  trigger: parent,
                  start:"top 95%",
                  end: "top 50%",
                  containerAnimation:tween,
                  scrub: 2,
                  pinnedContainer: ".timeline"
               }
            })
         });
         $fadeIn.each(function(){
            gsap.to($(this), {
               opacity: 1,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"top 80%",
                  scrub: 3,
                  end: "top 40%",
                  containerAnimation:tween,
                  pinnedContainer: ".timeline"
               }
            })
         });
          $parallax.each(function(i){
            const parent = $(this).parent();
            const speed = $(this).attr("data-speed");
            if (i % 2 === 0) { 
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3,
                     containerAnimation:tween,
                     fastScrollEnd: 3000,
                     pinnedContainer: ".timeline"
                  }
               }).fromTo($(this), 
               {x: () => window.innerWidth*.05*speed*-1,
               y: ()=> window.innerHeight},
               {x: () => window.innerWidth*.05*speed*-1,
               y: () => 0,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  x: () => window.innerWidth*.05*speed,
                  ease:"none",
                  duration: 3
               });
            } else {
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3,
                     containerAnimation:tween,
                     fastScrollEnd: 3000
                  }
               }).fromTo($(this), 
               {x: () => window.innerWidth*.05*speed,
               y: ()=> -1*window.innerHeight},
               {x: () => window.innerWidth*.05*speed,
               y: () => 0,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  x: () => window.innerWidth*.05*speed*-1,
                  ease:"none",
                  duration: 3
               });
            }
         });
         $musicBottom.each(function(){
            gsap.from($(this),{
               y: () => window.innerHeight*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"left 100%",
                  end:"left 50%",
                  containerAnimation:tween,
                  once: true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  pinnedContainer: ".timeline",
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
         $musicTop.each(function(){
            gsap.from($(this),{
               y: () => -1*window.innerHeight*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"left 100%",
                  end:"left 50%",
                  id:"first",
                  containerAnimation:tween,
                  once: true,
                  marker:true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  pinnedContainer: ".timeline",
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
      } else {
         $body.addClass("only_touch");
          document.querySelectorAll(".anchor").forEach(anchor => {
            anchor.addEventListener("click", function(e) {
               e.preventDefault();
               const id = this.getAttribute('href');
               gsap.to(".timeline", {duration: 2, scrollTo: {x:id, offsetX: 30}});
               });
         });
         $vertical.each(function() {
            const parent = $(this).parents(".era");
            const el = $(this);
            gsap.to(el, {
               left: '14vw',
               ease: "none",
               scrollTrigger: {
                  trigger: parent,
                  start:"left 95%",
                  end: "left 50%",
                  horizontal:true,
                  scrub: 2,
                  scroller:".timeline",
                  pinnedContainer: ".timeline"
               }
            })
         });
         $fadeIn.each(function(){
            gsap.to($(this), {
               opacity: 1,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"left 80%",
                  scrub: 3,
                  end: "top 40%",
                  horizontal:true,
                  scroller:".timeline",
                  pinnedContainer: ".timeline"
               }
            })
         });
         $parallax.each(function(i){
            const parent = $(this).parent();
            const speed = $(this).attr("data-speed");
            if (i % 2 === 0) { 
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3,
                     horizontal:true,
                     fastScrollEnd: 3000,
                     scroller:".timeline",
                     pinnedContainer: ".timeline"
                  }
               }).fromTo($(this), 
               {x: () => window.innerWidth*.05*speed*-1,
               y: ()=> window.innerHeight},
               {x: () => window.innerWidth*.05*speed*-1,
               y: () => 0,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  x: () => window.innerWidth*.05*speed,
                  ease:"none",
                  duration: 3
               });
            } else {
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3,
                     horizontal:true,
                     fastScrollEnd: 3000,
                     scroller:".timeline",
                     pinnedContainer: ".timeline"
                  }
               }).fromTo($(this), 
               {x: () => window.innerWidth*.05*speed,
               y: ()=> -1*window.innerHeight},
               {x: () => window.innerWidth*.05*speed,
               y: () => 0,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  x: () => window.innerWidth*.05*speed*-1,
                  ease:"none",
                  duration: 3
               });
            }
         });
         $musicBottom.each(function(){
            gsap.from($(this),{
               y: () => window.innerHeight*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"left 100%",
                  end:"left 50%",
                  horizontal:true,
                  once: true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  scroller:".timeline",
                  pinnedContainer: ".timeline",
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
         $musicTop.each(function(){
            gsap.from($(this),{
               y: () => -1*window.innerHeight*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"left 100%",
                  end:"left 50%",
                  id:"first",
                  horizontal:true,
                  once: true,
                  marker:true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  scroller:".timeline",
                  pinnedContainer: ".timeline",
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
      }
});
gsap.matchMedia().add("(max-width: 800px)", function(){
         document.querySelectorAll(".anchor").forEach(anchor => {
            anchor.addEventListener("click", function(e) {
               e.preventDefault();
               const id = this.getAttribute('href');
               gsap.to(window, {duration: 2, scrollTo: {y:id, offsetY:40}});
               });
         });
         $vertical.each(function() {
            const parent = $(this).parents(".era");
            const el = $(this);
            gsap.from(el, {
               y: () => window.innerHeight,
               ease: "none",
               scrollTrigger: {
                  trigger: parent,
                  start:"top 95%",
                  end: "top 50%",
                  scrub: 2
               }
            })
         });
         $fadeIn.each(function(){
            gsap.to($(this), {
               opacity: 1,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"top 80%",
                  scrub: 3,
                  end: "top 40%",
               }
            })
         });
          $parallax.each(function(i){
            const parent = $(this).parent();
            const speed = $(this).attr("data-speed");
            if (i % 2 === 0) { 
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3,
                  }
               }).fromTo($(this), 
               {x: () => window.innerWidth,
               y: ()=> window.innerHeight*.05*speed*-1},
               {x: () => 0,
               y: () => window.innerHeight*.05*speed*-1,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  y: () => window.innerHeight*.05*speed,
                  ease:"none",
                  duration: 4
               });
            } else {
               let tl = gsap.timeline({
                  scrollTrigger: {
                     trigger: parent,
                     scrub: 3
                  }
               }).fromTo($(this), 
               {x: () => -1*window.innerWidth,
               y: ()=> window.innerHeight*.05*speed},
               {x: () => 0,
               y: () => window.innerHeight*.05*speed,
               ease: "none",
            duration: 1}
               ).to ($(this), {
                  y: () => window.innerHeight*.05*speed*-1,
                  ease:"none",
                  duration: 4
               });
            }
         });
        $musicBottom.each(function(){
            gsap.from($(this),{
               x: () => window.innerWidth*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"top 100%",
                  end:"top 50%",
                  once: true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
         $musicTop.each(function(){
            gsap.from($(this),{
               x: () => -1*window.innerHeight*.5,
               ease: "none",
               scrollTrigger: {
                  trigger: $(this),
                  start:"top 100%",
                  end:"top 50%",
                  id:"first",
                  once: true,
                  scrub:0,
                  fastScrollEnd: 3000,
                  onLeave: function(thing){
                     const obj = thing.trigger;
                     $(obj).addClass("bounce");
                  }
               }
            })
         })
      }
)
}

app.scrollAnimations = function(){
   $blur.each(function(i){
       gsap.to($(this), {
            top: () => randomNumber(0, 70) + "%",
            left: () => randomNumber(0, 70) + "%",
            ease: "none",
            duration: 3,
            repeat: -1,
            repeatRefresh: true,
            delay: i*.5
         })
      });
      function randomNumber(min, max){
         return Math.floor(Math.random() * (1 + max-min) + min)
      }
}
app.desktopNav = function(){
   $parentLinks.hover(function(){
      $(this).addClass("show-sub");
   }, function(){
      $(this).removeClass("show-sub");
   }).focusin(function(){
      $(this).addClass("show-sub");
   }).focusout(function(){
      $(this).removeClass("show-sub");
   })
}

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}


app.init = function(){
   app.slider();
    app.mobileMenu();
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);
    app.tooltip();
    app.horizontalScroll();
   app.scrollAnimations();
   app.desktopNav();
   appHeight();
   window.addEventListener('resize', appHeight)
   $body.removeClass("preload");
}
